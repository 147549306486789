import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import ADMIN_CALCULATE_PERMIT_PASS_PRICE from "../../../../graphql/permitPass/queries/AdminCalculatePermitPassPrice";
import ADMIN_CALCULATE_PERMIT_PASS_PRICE_V2 from "../../../../graphql/permitPass/queries/AdminCalculatePermitPassPriceV2";
import { formatDate } from "./utils";

const usePriceCalculatorV2 = ({ apolloClient, formikbag, properties }) => {
  const { values, errors } = formikbag;

  const {
    amount,
    lot,
    startDate,
    startTime,
    endDate,
    endTime,
    typeOfPermit,
    price,
    permitKind,
    permitRecurringType,
  } = values;

  const isRecurring = permitKind === "RECURRING";
  const isCustomRate = price === "customRate";

  const query = isRecurring
    ? ADMIN_CALCULATE_PERMIT_PASS_PRICE_V2
    : ADMIN_CALCULATE_PERMIT_PASS_PRICE;

  useEffect(() => {
    console.log("amount", amount);
  }, [amount]);

  const [calculatePriceV2, { data, loading, error }] = useLazyQuery(query, {
    client: apolloClient,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    console.log("useEffect");

    const shouldCalculate =
      lot &&
      typeOfPermit === "paid" &&
      !errors.endDate &&
      !errors.endTime &&
      !errors.startDate &&
      !errors.startTime;

    if (!shouldCalculate) {
      return;
    }
    const lotSelected = properties.find(
      (property) => property.id === parseInt(lot)
    );

    const start = formatDate(startDate, startTime, lotSelected?.timezoneName);
    const end = formatDate(endDate, endTime, lotSelected?.timezoneName);

    // calculatePrice({
    //   variables: {
    //     lotId: values.lot,
    //     startTime: start,
    //     endTime: end,
    //   },
    // });

    calculatePriceV2({
      variables: {
        lotId: values.lot,
        startTime: start,
        endTime: end,
        kind: permitKind,
        ...(amount && isCustomRate && { customRate: amount }),
        ...(isRecurring && { recurringType: permitRecurringType }),
      },
    });
  }, [
    amount,
    lot,
    startDate,
    startTime,
    endDate,
    endTime,
    typeOfPermit,
    price,
    permitKind,
    permitRecurringType,
    errors.endDate,
    errors.endTime,
    errors.startDate,
    errors.startTime,
  ]);

  if (isRecurring) {
    return {
      data: data?.admin_calculatePermitPassPriceV2?.fixedPrice?.total,
      breakdown: data?.admin_calculatePermitPassPriceV2?.recurringBreakdown,
      loading,
      error,
    };
  }

  return {
    data: data?.admin_calculatePermitPassPrice?.total,
    loading,
    error,
  };
};

export default usePriceCalculatorV2;
