"use strict";

import React, { useState, useEffect, useMemo } from "react";

import {
  Grid,
  Label,
  Button,
  Icon,
  Input,
  Text,
  ToggleButton,
} from "@citifyd/style";
import moment from "moment-timezone";
import useAddPermitForm from "./useAddPermitForm.js";
import currencyFormatter from "@citifyd/currency-formatter";

import LoadingManager from "../../../../shared/react/components/LoadingManager";
import ApolloClient from "../../../../shared/services/apolloClient";
import FormikProvider from "../../../../shared/react/components/FormikProvider";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import RouterLink from "../../../../shared/react/components/RouterLink";
import { useTranslator } from "../../../../shared/react/hooks";
import styles from "../../AddPermit.module.scss";
import StickyFooter from "../../../../shared/react/components/StickyFooter";
import PhoneField from "../../../../shared/react/components/PhoneField";

import usePriceCalculatorV2 from "./usePriceCalculatorV2.js";
import { generateTimesArray } from "../../../../shared/services/helper";
import { getCurrentLanguage } from "../../../../shared/services/languages";
import { getFormattedDate } from "./utils.js";
import { useAuthentication } from "../../../../shared/react/contexts/authentication.js";
import {
  permitKind,
  permitRecurringTypes,
} from "../../../../shared/services/permits.js";
import { BreakdownTable } from "../BreakdownTable/BreakdownTable.jsx";

const apolloClient = ApolloClient();

const AddPermitForm = ({ permit, countries, properties }) => {
  const t = useTranslator();
  const language = getCurrentLanguage();
  const loadingMessage = permit
    ? t("permits.form.updating")
    : t("permits.form.creating");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRecurring, setIsRecurring] = useState(false);

  const { user } = useAuthentication();

  const times = generateTimesArray();

  const lotOptions = useMemo(
    () =>
      properties.map((lot) => ({
        value: lot.id,
        label: lot.name,
      })),
    []
  );

  const timeOptions = useMemo(
    () =>
      times.map((time) => ({
        value: time.time,
        label: time.label,
      })),
    [times]
  );

  const formikbag = useAddPermitForm({
    apolloClient,
    properties,
    user,
    permit,
    setErrorMessage,
    setHasError,
    setIsLoading,
  });

  const {
    data: calculatedPrice,
    breakdown: recurringBreakdown,
    loading: calculatingPrice,
    error: priceCalculationError,
  } = usePriceCalculatorV2({ apolloClient, formikbag, properties });

  const { handleSubmit, setFieldValue, values, setErrors, setTouched } =
    formikbag;

  const lotSelected = useMemo(
    () => properties.find((property) => property.id === parseInt(values.lot)),
    [properties, values.lot]
  );

  const currency = lotSelected?.country?.currency ?? "usd";

  useEffect(() => {
    const { typeOfPermit, price } = values;
    if (typeOfPermit === "free") {
      setFieldValue("price", null);
    } else if (!price) {
      setFieldValue("price", "existingRates");
    }
  }, [values.typeOfPermit]);

  const onClickTryAgain = () => {
    setHasError(false);
    setErrorMessage(null);
  };

  const onStartDateChange = (value) => {
    const endDateTime = getFormattedDate(values?.endDate, values?.endTime);
    const startDateTime = getFormattedDate(value, values?.startTime);

    if (moment(endDateTime).isBefore(startDateTime)) {
      const newEndDate = moment(startDateTime).add(1, "hour").toDate();

      setFieldValue("endDate", newEndDate);
      setErrors({});
      setTouched({});
    }
  };

  const onStartTimeChange = (e) => {
    const { value } = e.target;

    const startDateTime = getFormattedDate(values?.startDate, value);
    const endDateTime = getFormattedDate(values?.endDate, values?.endTime);

    if (moment(startDateTime).isSameOrAfter(endDateTime)) {
      const newEndDateTime = moment(startDateTime).add(1, "hour");

      setFieldValue("endTime", moment(newEndDateTime).format("HH:mm"));
      setFieldValue("endDate", moment(newEndDateTime).toDate());
      setErrors({});
      setTouched({});
    }
  };

  const togglePermitKind = async () => {
    setIsRecurring((currentState) => !currentState);
  };

  useEffect(() => {
    setFieldValue(
      "permitKind",
      isRecurring ? permitKind.RECURRING : permitKind.FIXED
    );
  }, [isRecurring]);

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
        errorMessage={errorMessage}
        onClickTryAgain={onClickTryAgain}
      />
    );
  }

  const PricingDetails = () => {
    if (
      !lotSelected ||
      formikbag.values.typeOfPermit === "free" ||
      priceCalculationError
    ) {
      return null;
    }

    if (calculatingPrice) {
      return <Text>Calculating price...</Text>;
    }

    if (
      formikbag.values.permitKind === "RECURRING" &&
      formikbag.values.permitRecurringType
    ) {
      return (
        <>
          <Text style={{ lineHeight: "24px", marginTop: 2 }}>
            The price amount will be calculated based on the rates configured
            for the property. If you change the rates before a permit is
            activated by the parker, the permit price will be recalculated. The
            current calculated amount for each date below is:
          </Text>
          {recurringBreakdown && (
            <BreakdownTable
              breakdown={recurringBreakdown}
              currency={currency}
              language={language}
            />
          )}
        </>
      );
    }

    if (
      calculatedPrice &&
      formikbag.values.permitKind === "FIXED" &&
      formikbag.values.price === "existingRates"
    ) {
      return (
        <Text style={{ lineHeight: "24px", marginTop: 10 }}>
          The price amount will be calculated based on the rates configured for
          the property. If you change the rates before a permit is activated by
          the parker, the permit price will be recalculated. The current
          calculated amount is{" "}
          <strong style={{ fontSize: 16, fontWeight: 600 }}>
            {currencyFormatter.format(calculatedPrice, {
              currency,
              language,
            })}
          </strong>
          .
        </Text>
      );
    }

    return null;
  };

  return (
    <FormikProvider value={formikbag}>
      <Grid.Row>
        <Grid.Col xs={12} md={6}>
          <Grid.Row style={{ marginBottom: 20 }}>
            <Grid.Col>
              <PhoneField
                placeholder={t("permits.form.phoneNumber")}
                countries={countries}
                countryCodeDefault={
                  permit?.phoneCountryCode || user?.phoneCountryCode || "us"
                }
                disabled={!!permit}
                label={t("permits.form.phone")}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row style={{ marginBottom: 20 }}>
            <Grid.Col>
              <FormikField
                as={FIELD_TYPE.SELECT}
                placeholder={t("permits.form.selectProperty")}
                options={lotOptions}
                label={t("permits.form.property")}
                name="lot"
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row style={{ marginBottom: 20 }}>
            <Grid.Col>
              <Grid.Row>
                <Grid.Col>
                  <Label>{t("permits.form.start")}</Label>
                  <div className={styles.col}>
                    <FormikField
                      as={FIELD_TYPE.DATEPICKER}
                      minDate={new Date()}
                      name="startDate"
                      shouldResetTime
                      requireTouchForError={false}
                      handleChange={onStartDateChange}
                    />

                    <div>
                      <FormikField
                        as={FIELD_TYPE.SELECT}
                        options={timeOptions}
                        gutterBottom
                        name="startTime"
                        hasError={Boolean(formikbag.errors.startDate)}
                        requireTouchForError={false}
                        handleChange={onStartTimeChange}
                        className={styles.selectTime}
                      />
                    </div>
                  </div>
                </Grid.Col>
                <Grid.Col>
                  <Label>{t("permits.form.end")}</Label>
                  <div className={styles.col}>
                    <FormikField
                      as={FIELD_TYPE.DATEPICKER}
                      minDate={new Date()}
                      name="endDate"
                      gutterBottom
                      shouldResetTime
                      requireTouchForError={false}
                    />

                    <div>
                      <FormikField
                        as={FIELD_TYPE.SELECT}
                        options={timeOptions}
                        name="endTime"
                        hasError={Boolean(formikbag.errors.endDate)}
                        requireTouchForError={false}
                      />
                    </div>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Grid.Row style={{ marginBottom: 20 }}>
            <Grid.Col sm={4}>
              <Label>{t("permits.form.typeOfPermit")}</Label>
              <div>
                <FormikField
                  as={FIELD_TYPE.RADIO}
                  name="typeOfPermit"
                  label={t("permits.form.free")}
                  value="free"
                  checked={formikbag.values.typeOfPermit === "free"}
                />
              </div>
              <FormikField
                as={FIELD_TYPE.RADIO}
                name="typeOfPermit"
                label={t("permits.form.paid")}
                value="paid"
                checked={formikbag.values.typeOfPermit === "paid"}
              />
            </Grid.Col>

            <Grid.Col sm={4}>
              <div className={styles.colDisabled}>
                <Label>{t("permits.form.price")}</Label>
                <div>
                  <FormikField
                    as={FIELD_TYPE.RADIO}
                    name="price"
                    label={t("permits.form.existingRates")}
                    value="existingRates"
                    checked={formikbag.values.price === "existingRates"}
                    disabled={formikbag.values.typeOfPermit === "free"}
                  />
                </div>
                <FormikField
                  as={FIELD_TYPE.RADIO}
                  name="price"
                  label={t("permits.form.customRate")}
                  value="customRate"
                  checked={formikbag.values.price === "customRate"}
                  disabled={formikbag.values.typeOfPermit === "free"}
                />
              </div>
            </Grid.Col>
            {formikbag.values.price === "customRate" && (
              <Grid.Col sm={4}>
                <Label>{t("permits.form.amount")}</Label>
                {values.price === "customRate" && (
                  <FormikField
                    name="amount"
                    as={FIELD_TYPE.CURRENCY}
                    currency={currency}
                  />
                )}
                {values.price !== "customRate" && (
                  <Input
                    disabled
                    value={currencyFormatter.format(
                      values.typeOfPermit === "free" || isNaN(calculatedPrice)
                        ? 0
                        : calculatedPrice,
                      { currency, language }
                    )}
                  />
                )}
              </Grid.Col>
            )}
          </Grid.Row>

          {formikbag.values.typeOfPermit === "paid" && (
            <Grid.Row style={{ marginBottom: 20 }}>
              <Grid.Col xs={5} md={4}>
                <div>
                  <Label>{t("permits.form.recurring")}</Label>
                  <ToggleButton
                    customClassName={styles.toggle}
                    selected={formikbag.values.permitKind === "RECURRING"}
                    light
                    onChange={togglePermitKind}
                  />
                </div>
              </Grid.Col>
              {isRecurring && (
                <Grid.Col xs={7} md={8}>
                  <FormikField
                    as={FIELD_TYPE.SELECT}
                    label={t("permits.form.recurringFrequency")}
                    name="permitRecurringType"
                    options={permitRecurringTypes}
                  />
                </Grid.Col>
              )}
            </Grid.Row>
          )}

          <Grid.Row style={{ marginBottom: 20 }}>
            <Grid.Col>
              <PricingDetails />
              {priceCalculationError &&
                formikbag.values.typeOfPermit !== "free" && (
                  <Text appearance="error">
                    {priceCalculationError.message}
                  </Text>
                )}
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>

      <StickyFooter justifyContent="space-between">
        <RouterLink
          state={permit ? "permit" : "permits"}
          params={permit ? { permitId: permit.id } : null}
          className={styles.backLink}
        >
          <Icon size="subtitle" icon="chevron-left" appearance="white" />
          <Text variant="subtitle" appearance="white">
            {t("commonButtons.goBackWithoutSaving")}
          </Text>
        </RouterLink>
        <Button
          uppercase
          disabled={priceCalculationError}
          onMouseDown={handleSubmit}
          extraPadding
        >
          {t("permits.form.save")}
        </Button>
      </StickyFooter>
    </FormikProvider>
  );
};

export default AddPermitForm;
