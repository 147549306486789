export const permitRecurringTypes = [
  { label: "Weekly", value: "WEEKLY" },
  { label: "Biweekly", value: "BIWEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
  { label: "Quarterly", value: "THREE_MONTHLY" },
  { label: "Yearly", value: "YEARLY" },
];

export const permitKind = {
  FIXED: "FIXED",
  RECURRING: "RECURRING",
};
