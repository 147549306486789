import gql from "graphql-tag";

export default gql`
  query AdminCalculatePermitPassPriceV2(
    $lotId: Int!
    $startTime: DateTime!
    $endTime: DateTime
    $kind: PermitPassKind!
    $recurringType: PermitPassRecurringType
    $customPrice: Int
  ) {
    admin_calculatePermitPassPriceV2(
      lotId: $lotId
      startTime: $startTime
      endTime: $endTime
      kind: $kind
      recurringType: $recurringType
      customPrice: $customPrice
    ) {
      fixedPrice {
        value
        fee
        total
      }
      recurringBreakdown {
        price {
          value
          fee
          total
        }
        startTime
        endTime
      }
    }
  }
`;
